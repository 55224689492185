// LocationSelector.tsx
import React, { useState } from 'react';
import { PageTitle } from '../../atoms/TextStyles';

type Location = {
  id: number;
  name: string;
  address: string;
  imageUrl: string;
};

const locations: Location[] = [
  {
    id: 1,
    name: 'Da FOOD TRUCK',
    address: 'prob UCLA campus',
    imageUrl: './images/loc/loc1.png',
  },
  {
    id: 2,
    name: 'Cyber Truck',
    address: 'near the food truck',
    imageUrl: './images/loc/loc2.png',
  },
  {
    id: 3,
    name: 'Truck 2.0',
    address: 'UCLA',
    imageUrl: './images/loc/loc3.png',
  },
  {
    id: 3,
    name: 'le Café',
    address: '606 venice blvd venice ca 90291',
    imageUrl: './images/loc/loc4.png',
  },
];

const LocationSelector: React.FC = () => {
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);

  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto', marginTop:"75px" }}>

    <PageTitle>SELECT A LOCATION</PageTitle>

      {locations.map((location) => (
        <div
          key={location.id}
          onClick={() => setSelectedLocation(location)}
          style={{
            width: '100%',
            padding: '16px',
            margin: '8px 0',
            border: location === selectedLocation ? '1px solid #FF00B8' : '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer',
            backgroundColor: location === selectedLocation ? '#FEBFED' : '#FFF2FC',
            boxShadow: location === selectedLocation ? '0 0px 0px rgba(0, 0, 0, 0.2)' : 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={location.imageUrl}
            alt={location.name}
            style={{
              width: '60px',
              height: '60px',
              objectFit: 'cover',
              borderRadius: '4px',
              marginRight: '16px',
            }}
          />
          <div>
            <h3 style={{ margin: '0 0 4px',color:'#333333',fontFamily:'Inter',fontWeight:800, textDecoration:'uppercase' }}>{location.name}</h3>
            <p style={{ margin: 0,fontFamily:'Inter', color:'#FF00B8' }}>{location.address}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LocationSelector;
