import React from "react";
import { GlowParticle } from "./glowparticle";

// const COLORS = [
//     {r: 45, g: 74, b: 227},
//     {r:250, g:255, b:89},
//     {r: 255, g:104, b:248},
//     {r:44, g:209, b:252},
//     {r:54, g:233, b:84},
// ]

const COLORS = [
  { r: 0xF8, g: 0x00, b: 0x6E },
  { r: 0xF8, g: 0x00, b: 0xB5 },


  //{ r: 245, g: 55, b: 54 },
  //{r: 231, g:56, b:112},
  //{r: 227, g:10, b:143},
  //{r: 255, g:0, b:184},
  // { r: 246, g: 74, b: 195 },
  // //{ r:238, g:114, b:241},
  // { r: 183, g: 128, b: 247 },
  // { r: 124, g: 180, b: 255 },
];

const DESIRED_DELAY = 1000 / 15;

//NAV_BAR_OFFSET is a hack to get past the Viewport resizing on Safari Mobile when the address bar is shown/hidden during scrolling.
//We do it for all browsers for coding simplicity.
const NAV_BAR_OFFSET = 100

class ValeBackground extends React.Component {
  ctx?: CanvasRenderingContext2D | null;
  totalParticles?: number;
  particules?: GlowParticle[];
  maxRadius?: number;
  minRadius?: number;
  stageWidth?: number;
  stageHeight?: number;
  canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
  divRef: React.RefObject<HTMLDivElement> = React.createRef();
  lastFrameTime = 0;

  resize() {
    try {
      if (!this.canvasRef.current || !this.ctx) return;
      this.stageWidth = window.innerWidth //document.body.clientWidth;
      this.stageHeight = window.innerHeight + NAV_BAR_OFFSET//document.body.clientHeight;

      this.canvasRef.current.width = this.stageWidth
      this.canvasRef.current.height = this.stageHeight

    } catch (e) {
      console.warn(`ValeBackground : resize : e`);
    }
  }
  redraw() {
    if (!this.particules || !this.totalParticles) return;

    for (let i = 0; i < this.totalParticles; i++) {
      this.particules[i].redraw(
        this.ctx!,
      );
    }

  }

  createParticles() {
    if (!this.totalParticles || !this.maxRadius || !this.minRadius) return;

    let curColor = 0;
    this.particules = [];

    for (let i = 0; i < this.totalParticles; i++) {
      const item = new GlowParticle(
        Math.random() * this.stageWidth!,
        Math.random() * this.stageHeight!,
        Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
        COLORS[curColor],
      );

      if (++curColor >= COLORS.length) {
        curColor = 0;
      }

      this.particules[i] = item;
    }
  }

  animate(currentTime: number) {
    if (!this.particules || !this.totalParticles) return;
    if (!this.lastFrameTime) {
      this.lastFrameTime = currentTime;
    }

    // Calculate the time elapsed since the last frame
    const timeElapsed = currentTime - this.lastFrameTime;

    // If enough time has elapsed, update the animation
    if (timeElapsed > DESIRED_DELAY) {
      // desiredDelay controls the speed, e.g., 1000/30 for 30 fps
      // Update your animation properties here
      this.ctx?.clearRect(0, 0, this.stageWidth!, this.stageHeight!);
      for (let i = 0; i < this.totalParticles; i++) {
        this.particules[i].animate(
          this.ctx!,
          this.stageWidth!,
          this.stageHeight!,
        );
      }
      // Reset the last frame time to the current time
      this.lastFrameTime = currentTime;
    }
    //We should slow this down
    window.requestAnimationFrame(this.animate.bind(this));
  }
  onResize() {
    this.resize();
    this.redraw();
  }

  componentDidMount() {
    try {
      this.ctx = this.canvasRef.current!.getContext("2d");

      this.totalParticles = 15; //15
      this.particules = [];
      this.maxRadius = 300; // 900
      this.minRadius = 100; // 400

      window.addEventListener('resize', this.onResize.bind(this), false);
      this.resize();
      this.createParticles();
      this.ctx!.globalCompositeOperation = "saturation";
      window.requestAnimationFrame(this.animate.bind(this));
    } catch (e) {
      console.log(`ValeBackground : componentDidMount : e`);
    }
  }

  render() {
    return (
      <div
        ref={this.divRef}
        id='valebackground'
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: -NAV_BAR_OFFSET,
          right: 0,
          // width: '100%',
          // height: '100%',
          zIndex: -1,
          backgroundColor: "#D57CEB", //"#D57CEB"
        }}
      >
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}

export default ValeBackground;
