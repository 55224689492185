import React, { useState } from 'react';
import { FOOT_PADDING } from '../../../../molecules/footer';
import { useCartContext } from '../../../../../system/CartProvider';
import { useValeContext } from '../../../../../system/ValeSystem';

const PromoCodeComponent: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const system = useValeContext();

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPromoCode(e.target.value.toUpperCase());
        setIsValid(null);
        setErrorMessage(null); // Clear any error messages when the user starts typing
    };

    const cartContext = useCartContext();

    const handleApply = async () => {
        try {

            const response = await system?.valeClientOpenapi.checkPromocode(promoCode);
            if (response?.status === 200 && response.data?.status?.code === "ok") {
                if (response.data?.codeStatus === "valid" && response.data.code) {
                    // Apply discount for general promo codes
                    setIsValid(true);
                    cartContext?.setPromoCode?.(response.data.code.code);
                    return
                } else if (response.data?.codeStatus === "consumed") {
                    setErrorMessage('Promo code only valid for first-time orders.');
                    setIsValid(false);
                    cartContext?.setPromoCode?.(null);
                    return
                }
                else if (response.data?.codeStatus === "expired") {
                    setErrorMessage('Promo code expired');
                    setIsValid(false);
                    cartContext?.setPromoCode?.(null);
                    return;
                } else {
                    console.warn('Unhandled promo code status:', response.data?.codeStatus);
                }
            }
        } catch (error) {
            console.error('Error applying promo code:', error);
        }

        // Set error message if the promo code is invalid
        setErrorMessage('Invalid promo code. Please try again.');
        setIsValid(false);
        cartContext?.setPromoCode?.(null);
    };

    return (
        <div style={styles.container}>
            <div style={styles.topBar} onClick={handleToggle}>
                <span style={styles.topBarText}>HAVE A PROMO CODE?</span>
                <span style={styles.arrow}>{isExpanded ? <img src='/up_arrow.png' style={styles.imgArrow} /> : <img src='/down_arrow.png' style={styles.imgArrow} />}</span>
            </div>

            {isExpanded && (
                <div style={styles.expanded}>
                    <center>
                        <div style={styles.expandedHolder}>


                            <input
                                style={styles.input}
                                type="text"
                                placeholder="Enter code"
                                value={promoCode}
                                onChange={handlePromoCodeChange}
                            />
                            <button style={styles.button} onClick={handleApply}>
                                APPLY
                            </button>


                            {/* Display error messages or success message */}
                            {errorMessage && <p style={styles.errorText}>{errorMessage}</p>}
                            {isValid && !errorMessage && <p style={styles.successText}>Promo code applied successfully!</p>}
                        </div>
                    </center>
                </div>
            )}
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        position: 'fixed',
        width: '100%',
        backgroundColor: '#EE72F1',
        fontFamily: 'Inter, BN Dime Display',
        border: '2px solid black',
        bottom: FOOT_PADDING,
    },
    topBar: {
        backgroundColor: '#EE72F1',
        color: '#2C2C2C',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
        fontFamily: 'BN Dime Display',
    },
    topBarText: {
        fontSize: '20px',
        fontWeight: 500,
        padding: '10px 20px',
    },
    imgArrow: {
        width: "25px",
    },
    arrow: {
        padding: '10px 20px',
    },
    expanded: {
        width: '100%',
        backgroundColor: '#EE72F1',
        borderTop: '2px solid #2C2C2C',
    },
    expandedHolder: {
        width: '100%',
        marginTop: '20px',
    },
    input: {
        padding: '15px',
        fontSize: '18px',
        outline: 'none',
        color: '#D787FF',
        backgroundColor: '#FFF',
        width: '50%',
        borderRadius: '10px 0px 0px 10px',
        marginBottom: '20px',
        border: "2px solid #2C2C2C",
    },
    button: {
        padding: '15px 20px',
        backgroundColor: '#FFEA56',
        color: '#2C2C2C',
        fontSize: '18px',
        fontWeight: 700,
        cursor: 'pointer',
        outline: 'none',
        borderRadius: '0px 10px 10px 0px',
        border: "2px solid #2C2C2C",
        borderLeft: 'none',
    },
    errorText: {
        color: '#4712B1',
        fontSize: 16,
        fontWeight: 800,
        fontFamily: "Inter",
        fontStyle: "italic",
    },
    successText: {
        color: 'green',
        fontSize: 16,
        fontWeight: 800,
        fontFamily: "Inter",
        fontStyle: "italic",
    },
};

export default PromoCodeComponent;
