import Page from "../../../../templates/Page";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useNoSessionContext } from "../../../../../system/NoSessionProvider";
import { useSessionContext } from "../../../../../system/SessionProvider";
import { useCallback, useMemo, useRef } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { PageTitle, PageSubtitle } from "../../../../atoms/TextStyles";
import "./SelectionPage.css"
import { useCartContext } from "../../../../../system/CartProvider";
import CheckoutBar from "./CheckoutBar";
import { DrinkNameEntryModal, DrinkNameEntryModalHandle } from "./DrinkNameEntry";
import Footer from "../../../../molecules/footer";
import { OrderStackParamList } from "../../../order/order_param_list";
import { MenuItem } from "../../../../../vale_common";
import { previewSrc } from "./reviewOrderUtil";
import { useValeContext } from "../../../../../system/ValeSystem";
import { Box } from "@mui/material";
import { getStoreStatus } from "../../../../../system/menu/menuUtils";
import SelectionView from "./SelectionList";

const SelectionPage: React.FC<
  NativeStackScreenProps<OrderStackParamList, "selection">
> = (props) => {
  const vale = useValeContext();
  const cart = useCartContext();
  const noSessionInfo = useNoSessionContext();
  const session = useSessionContext();
  const drinkNameModalRef = useRef<DrinkNameEntryModalHandle>(null);

  const navigation = useNavigation<StackNavigationProp<OrderStackParamList>>();

  const onCheckout = useCallback(() => {
    navigation.push("cart", {});
  }, [])

  const storeIsClosed = useMemo(() => {
    const storeStatusInfo = getStoreStatus(noSessionInfo?.locations);

    return storeStatusInfo?.storeStatus != "OPEN"
  }, [noSessionInfo?.locations]);

  const onPress = useCallback(async (menuItem: MenuItem) => {
    cart?.addItem({ itemTypeId: menuItem.id!, itemTypeVariationId: menuItem.variations![0].id!, price: menuItem.variations?.[0].price || {}, name: menuItem.name! });

    return true;
  }, [drinkNameModalRef, cart!.orderName, cart?.setOrderName, cart?.addItem, vale?.valeClientOpenapi])


  return (
    <Page>
      <div >
        <PageTitle>{storeIsClosed ? "PEEP THE MENU" : "CHOOSE YOUR DRINK"}</PageTitle>
        <PageSubtitle>{storeIsClosed ? "We're not open right now but feel free to take a look around. " : "All drinks are 12oz of 100% pure flavor."}</PageSubtitle>
      </div>

      <SelectionView onAdd={onPress} />

      <Footer />
      <CheckoutBar onCheckout={onCheckout} />
      <DrinkNameEntryModal ref={drinkNameModalRef} />
    </Page>
  );
};

export default SelectionPage;
